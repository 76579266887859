import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  ownLocations: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { payload } = action;
      state.user = payload;
    },
    removeUser: (state, action) => {
      state.user = null;
      console.log("remove User")
    },
    setOwnLocations: (state, action) => {
      const { payload } = action;
      state.ownLocations = payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const { setUser, removeUser, setOwnLocations } = userSlice.actions;

export default userSlice.reducer;
