import React from "react";

import { httpsCallable } from "firebase/functions";
import { auth, functions, storage } from '../firebase/firebase';

import { firestore as db } from "../firebase/firebase";
import { doc, getDoc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';

import { signInWithPopup, updateEmail, updatePassword, deleteUser, sendPasswordResetEmail, createUserWithEmailAndPassword, signOut, getAuth, signInWithEmailAndPassword  } from "firebase/auth";
import { resolvePath, useNavigate  } from "react-router-dom"
import { provider } from "../firebase/firebase";

import { useSelector } from "react-redux";
import { store } from '../redux/store'
import { setUser } from "../redux/userSlice";

import { getDownloadURL, ref as ref_storage, uploadBytesResumable, uploadString } from "firebase/storage";

import { v4 as uuidv4 } from 'uuid';

import { ref, deleteObject } from "firebase/storage";
import { setOwnLocations } from "../redux/userSlice";
async function UpdatedUserState(){
    const result = await getOwnUserObject()
    store.dispatch(setUser(result));
}

async function deleteProfilePicture({user}){
    if(user.profile_picture_id){
        console.log("JA")
        const imagePath = `images/${user.profile_picture_id}`;
        const pathtRef = ref(storage, imagePath)
        const result = await deleteObject(pathtRef)
        try{
            const userRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userRef, {
                profile_picture_id: '',
                profile_picture_url: ''
            });
        }catch(e){
            console.log(e)
        }
        UpdatedUserState()
    }
}

async function getOwnUserObject(){
    return new Promise(async (resolve, reject) => {
        try {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists) {
                resolve(userDocSnapshot.data())
            } else {
                console.log('Das Dokument existiert nicht.');
                reject()
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten aus Firestore:', error);
            reject(error)
        }
    })
}

async function updateUserProfilePicture({id, url}){
    try{
        const userRef = doc(db, 'users', auth.currentUser.uid);
        
        await updateDoc(userRef, {
            profile_picture_id: id,
            profile_picture_url: url
        });
    }catch(e){
        console.log(e)
    }
}

async function getThisDownloadURL(imageId, type) {
    return new Promise(async (resolve, reject) => {
    const imageRef = ref_storage(storage, `${type}/${imageId}`);
        try {
            const imageURL = await getDownloadURL(imageRef)
            resolve(imageURL)
        } catch (e) {
            console.log(e)
            reject(e)
        }
    })
}

function uploadImage(imageURI) {
    return new Promise(async (resolve, reject) => {
        let newImageId = uuidv4()
        const storageRef = ref_storage(storage, `images/${newImageId}`);
        try {
            console.log("imageURI", imageURI)
            await uploadString(storageRef, imageURI, 'data_url')
            console.log(`successfully uploaded to Firebase Storage`);
            const downloadURL = await getThisDownloadURL(newImageId, "images")
            resolve({id: newImageId, url: downloadURL})
        }
        catch (e) {
          console.log(e); reject(e) }
      })
}

const getCustomerId = async (userId) => {
    try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const customerId = userData.customer_id;
            return customerId;
        } else {
            console.log('Benutzerdokument nicht gefunden');
            return null;
        }
    } catch (error) {
        console.error('Fehler beim Abfragen des customerId:', error);
        return null;
    }
};

async function getCustomer(auth) {
    try {
      const customerId = await getCustomerId(auth.currentUser.uid);
      return customerId;
    } catch (error) {
      console.error('Fehler beim Abrufen der Kunden-ID:', error);
      throw error;
    }
  }
  
async function login(email, password){
    return new Promise(async (resolve, reject) => {
        const auth = getAuth();
        try {
            const result = await signInWithEmailAndPassword(auth, email, password)
            resolve(result)
        } catch (error) {
            reject(error)
        }
    })
}

async function register(email, password) {
    return new Promise(async (resolve, reject) => {
        const auth = getAuth();
        try {
            const result = await createUserWithEmailAndPassword(auth, email, password)
            resolve(result)
        } catch (error) {
            reject(error)
        }
    })
}

async function SignInWithGoogle(){
    return new Promise(async (resolve, reject) => {
        const auth = getAuth();
        try{
            const result = await signInWithPopup(auth, provider)
            resolve(result)
        } catch (error) {
            reject(error)
        }

    })
}

async function deleteUserAccount(){
    return new Promise(async (resolve, reject) => {
        const user = auth.currentUser;
        try{
            const result = deleteUser(user)
            console.log("Account erfolgreich gelöscht")
            try{
                //Firestore document delete
                const auth = getAuth();
                const userRef = doc(db, 'users', auth.currentUser.uid);
                await deleteDoc(userRef);
            } catch (error) {
                console.log("error", error)
                reject (error)
            }
            resolve(result)
        } catch (error) {
            reject (error)
        }
    })
}

function logout(){
    const auth = getAuth();
    return signOut(auth)
}

function SetNewEmail(email) {
    updateEmail(auth.currentUser, email)
      .then(() => {
        console.log("Email updated!");
      })
      .catch((error) => {
        console.log("Error");
      });
  }


function SetNewPassword(password){
    updatePassword(auth.currentUser, password).then(() => {
        console.log("Password updated!")
      }).catch((error) => {
        console.log("Error")
      });
}

function resetPassword(email){
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email)
}

const addUserDocument = async () => {
    try {
        const userUid = auth.currentUser.uid
        const userDocRef = doc(db, 'users', userUid);

        try {
            await setDoc(userDocRef, {
                counter: 0,
                email: auth.currentUser.email,
                uid: auth.currentUser.uid,
                customer_id: '',
                default_payment_method: '',
                profile_picture_id: '',
                profile_picture_url: ''
            });
        } catch (error) {
            console.error('Fehler beim erstellen des Docs:', error);
        }
    } catch (error) {
        console.error('Fehler beim Hinzufügen des Dokuments:', error);
    }
};

export async function uploadLocation(link, name, imageURI, ownLocations){
    return new Promise(async (resolve, reject) => {
        /**Das Image wird gespeichert */
        let newImageId = null
        if(imageURI){
            newImageId = uuidv4()
        } else {
            newImageId = "none"
        }
        
        const storageRef = ref_storage(storage, `locationImages/${newImageId}`);
        let downloadURL = null

        try {
            /**
             * Bild wird im Storage gespeichert
             * */
            if(imageURI){
                await uploadString(storageRef, imageURI, 'data_url')
                downloadURL = await getThisDownloadURL(newImageId, "locationImages")
            } else {
                downloadURL = "none"
            }
        } catch (e) {
          console.log(e); reject(e) 
        }


        /**
         * Link und Name werden gespeichert 
         * BildUrl und BildId aus dem Storage werden in der entsprechenden Location gespeichert.
         * */
        const uploadLocationCloud = httpsCallable(functions, 'baseEndpoints-uploadLocation');
        let locationKey = null;
        try {
            await uploadLocationCloud({link, name, newImageId, downloadURL}).then(result => {
                // resolve(result)
                locationKey = result.locationKey
            }).catch(error => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }

        /**Füge die neue location key in user State hinzu */
        const result = await getOwnUserObject();
        store.dispatch(setUser(result));

        /**Füge Location object in ownLocations hinzu */
        const newLocation = {
            googleReviewURL: link,
            locationImageId: newImageId,
            logoURL: imageURI,
            name: name,
            locationKey: locationKey
        }
        const newOwnLocations = [...ownLocations, newLocation]
        store.dispatch(setOwnLocations(newOwnLocations));

        resolve({id: newImageId, url: downloadURL})
        return resolve({id: newImageId, url: downloadURL});
    });
}

export function getOwnLocations(locations){
    return new Promise(async (resolve, reject) => {
        const getOwnLocationsCloud = httpsCallable(functions, 'baseEndpoints-getOwnLocations');
        await getOwnLocationsCloud({locations}).then(result => {
            // resolve(result)
            // console.log("sideEffects Result:", result)
            resolve(result)
            return result
        }).catch(error => {
            reject(error)
        });
    })
}

export function getLocationFeedback(startDate, endDate, locationKey){
    return new Promise(async (resolve, reject) => {
        const getLocationFeedbackCloud = httpsCallable(functions, 'baseEndpoints-getLocationFeedbackCloud');
        await getLocationFeedbackCloud({startDate, endDate, locationKey}).then(result => {
            // console.log(result)
            resolve(result)
        }).catch(error => {
            reject(error)
        });
    })
}
export { 
    getCustomer, 
    getCustomerId,
    login,
    SignInWithGoogle,
    register,
    addUserDocument,
    deleteUserAccount,
    logout,
    SetNewEmail,
    SetNewPassword,
    resetPassword,
    uploadImage,
    updateUserProfilePicture,
    getOwnUserObject,
    deleteProfilePicture
};