import React from 'react'
import NavigationBar from './NavigationBar'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import QRCode from 'react-qr-code';
import { getLocationFeedback } from '../sideEffects/sideEffects';
import { DateInput } from '@mantine/dates';
export default function LocationDetailScreen() {
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.user);
    const locations = useSelector((state) => state.user.user.locations);
    const ownLocations = useSelector((state) => state.user.ownLocations);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const locationKey = searchParams.get('location');

    const isLocationValid = locations.includes(locationKey);

    const [dateStart, setDateStart] = useState(null);
    const [dateEnde, setDateEnde] = useState(null);
    const [dateError, setDateError] = useState(null);

    const [actions, setActions] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const [uniqueViews, setUniqueViews] = useState([]);
    const [combinedActionsFeedback, setCombinedActionsFeedback] = useState([]);

    const [aufrufe, setAufrufe] = useState(0);
    const [lokaleBewertung, setLokaleBewertung] = useState(0);
    const [schnittLokaleBewertung, setSchnittLokaleBewertung] = useState(0);
    const [weiterleitungen, setWeiterleitungen] = useState(0);

    const [name, setname] = useState("..loading")
    const [logoURL, setlogoURL] = useState("..loading")
    const [googleReviewURL, setgoogleReviewURL] = useState("..loading")

    function setDateEndeFunc(value){
        if(value>dateStart){
            setDateError("")
            setDateEnde(value)
        } else {
            setDateEnde(value)
            setDateError("Fehler: Der angegebene Zeitraum ist ungültig!")
        }
    }

    function setDateStartFunc(value){
        if(value<dateEnde){
            setDateError("")
            setDateStart(value)
        } else {
            setDateStart(value)
            setDateError("Fehler: Der angegebene Zeitraum ist ungültig!")
        }
    }

    if(!isLocationValid){
        navigate(-1)
    }

    useEffect(() => {
        // Funktion, um das Datum von vor einem Monat zu erhalten
        const getOneMonthAgo = () => {
          const today = new Date();
          const lastMonth = new Date(today);
          lastMonth.setMonth(today.getMonth() - 1);
          return lastMonth;
        };
    
        // Funktion, um das aktuelle Datum zu erhalten
        const getCurrentDate = () => {
          return new Date();
        };
    
        // Setze die State-Variablen beim Seitenaufruf
        setDateStart(getOneMonthAgo());
        setDateEnde(getCurrentDate());
      }, []);

    
    useEffect(() => {
        if (isLocationValid) {
            const foundLocation = ownLocations.find((location) => location.locationKey === locationKey);

            if (foundLocation) {
                setname(foundLocation.name);
                setlogoURL(foundLocation.logoURL);
                setgoogleReviewURL(foundLocation.googleReviewURL);
            }
        } else {
            navigate(-1);
        }
        if (isLocationValid) {
            // 
        }
    }, [isLocationValid, locationKey, ownLocations, navigate]);

    useEffect(() => {
        const fetchData = async () => {
          if (dateStart && dateEnde && !dateError) {
            try {
              const result = await getLocationFeedback(dateStart, dateEnde, locationKey);
              setActions(result.data.actions)
              setFeedback(result.data.feedback)
              setUniqueViews(result.data.uniqueViews)
              console.log("result.data", result.data)
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          }
        };
      
        fetchData();
    }, [dateStart, dateEnde, dateError, locationKey]);

    useEffect(() => {
        if(actions){
            // console.log("actions" ,actions)
            
            const rateCount = actions.filter(item => item.action === "rate").length;
            setLokaleBewertung(rateCount)

            const googleCount = actions.filter(item => item.action === "google").length;
            setWeiterleitungen(googleCount)
        }
        if(feedback){
            // console.log("feedback" ,feedback)

        }
        if(uniqueViews){
            // console.log("uniqueViews" ,uniqueViews)
            setAufrufe(uniqueViews.length)
        }
        if(feedback&&actions){
            // Add 'collection' attribute to each object
            const actionsWithCollection = actions.map(item => ({ ...item, collection: 'action' }));
            const feedbackWithCollection = feedback.map(item => ({ ...item, collection: 'feedback' }));

            // Combine actions and feedback into a new list
            const combinedList = [...actionsWithCollection, ...feedbackWithCollection];
            // const sortedcombinedList = combinedList.sort((a, b) => new Date(a.date) - new Date(b.date));
            const sortedcombinedList = combinedList.sort((a, b) => new Date(b.date) - new Date(a.date));

            setCombinedActionsFeedback(sortedcombinedList)
            // console.log("sortedcombinedList: ",sortedcombinedList)
        }
    }, [actions, feedback, uniqueViews]);

    useEffect(() => {
        if(lokaleBewertung&&aufrufe){
            setSchnittLokaleBewertung(aufrufe/lokaleBewertung);
        }
    }, [lokaleBewertung, aufrufe]);

    function formatiereDatum(urspruenglichesDatum) {
        const options = {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        };
      
        const umgewandeltesDatum = new Date(urspruenglichesDatum).toLocaleDateString('de-DE', options);
      
        return umgewandeltesDatum;
      }

    return (
        <div style={{display:"flex", width:"100%", height:"100vh"}}>
            <NavigationBar showArrow={true} showAvater={true} LinkAvatar="/settings" LinkArrow="/"/>
            <div style={{display:"flex", width:"100%", height:"100%", paddingTop:"65px", justifyContent:"center", alignItems:"center"}}>
                <div style={{display:"flex", width:"100%", height:"100%", paddingTop:"20px", paddingBottom:"20px", paddingLeft:"20px", paddingRight:"20px", justifyContent:"center", alignItems:"center"}}>
    
                {/**INHALT DER SEITE*/}
                <div style={{width:"100%", height:"100%"}}>

                    {/** HEADER*/}
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "130px", justifyContent: "space-between" }}>
                        
                        {/** Erstes Div im Header */}
                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"flex-start", alignItems:"center"}}>
                            <img
                                src={logoURL}
                                alt="Location Image"
                                style={{ maxHeight: '100%', width: 'auto', height: '100%' }}
                            />
                            <div style={{display:"flex", flexDirection:"column", height:"100%", width:"100%", justifyContent:"center", paddingLeft:"20px"}}>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"30px", fontStyle:"normal", fontWeight:"600", lineHeight:"125%"}}>{name}</div>
                                <div style={{color:"#747474", fontFamily:"Hanken Grotesk", fontSize:"22px", fontStyle:"normal", fontWeight:"350", lineHeight:"125%"}}>{locationKey}</div>
                            </div>
                        </div>

                        {/** Zweites Div im Header */}
                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"flex-start", alignItems:"center"}}>
                            <QRCode
                            size={256}
                            style={{ height: "100%", maxWidth: "100%", width: "100%" }}
                            value={`review-base-app.de/${locationKey}`}
                            viewBox={`0 0 256 256`}
                             />
                        </div>

                        {/** Drittes Div im Header */}
                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"flex-start", alignItems:"center"}}>
                            <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"25px", fontStyle:"normal", fontWeight:"500", lineHeight:"125%"}}>review-base-app.de/{locationKey}</div>
                        </div>
                    </div>

                    {/**Divider */}
                    <div style={{width:"100%",height:"1px", backgroundColor:"black", marginTop:"20px", marginBottom:"20px"}}></div>
                    
                    {/**Date Input */}
                    <div style={{display:"flex", width:"100%", height:"80px", backgroundColor: "", justifyContent:"flex-start", alignItems:"flex-start", gap:"20px"}}>
                        <DateInput
                        value={dateStart}
                        onChange={setDateStartFunc}
                        label="Von"
                        placeholder="Datum"
                        size="lg"
                       />
                       <DateInput
                        value={dateEnde}
                        onChange={setDateEndeFunc}
                        label="Bis"
                        placeholder="Datum"
                        size="lg"
                        />
                    </div>

                    {/**Date Error Message */}
                    <div style={{display:"flex", width:"100%", height:"30px", backgroundColor: "", justifyContent:"flex-start", alignItems:"center", gap:"20px"}}>
                        {dateError && <p style={{color:"red"}}>{dateError}</p>}
                    </div>

                    {/**Daten Übersicht */}
                    <div style={{display:"flex", width:"100%", height:"140px", backgroundColor: "", justifyContent:"flex-start", alignItems:"center", paddingLeft:"90px", paddingRight:"90px"}}>
                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"center", alignItems:"center"}}>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", textAlign:"center"}}>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"20px", fontStyle:"normal", fontWeight:"400", lineHeight:"125%"}}>Aufrufe</div>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"40px", fontStyle:"normal", fontWeight:"600", lineHeight:"125%"}}>{aufrufe}</div>
                            </div>
                        </div>
                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"center", alignItems:"center"}}>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", textAlign:"center"}}>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"20px", fontStyle:"normal", fontWeight:"400", lineHeight:"125%"}}>Lokale Bewertungen</div>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"40px", fontStyle:"normal", fontWeight:"600", lineHeight:"125%"}}>{lokaleBewertung}</div>
                            </div>
                        </div>
                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"center", alignItems:"center"}}>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", textAlign:"center"}}>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"20px", fontStyle:"normal", fontWeight:"400", lineHeight:"125%"}}>Dursch. lokale Bewertung</div>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"40px", fontStyle:"normal", fontWeight:"600", lineHeight:"125%"}}>{schnittLokaleBewertung}</div>
                            </div>
                        </div>
                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"center", alignItems:"center"}}>
                            <div style={{display:"flex", flexDirection:"column", gap:"10px", textAlign:"center"}}>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"20px", fontStyle:"normal", fontWeight:"400", lineHeight:"125%"}}>Weiterleitungen</div>
                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"40px", fontStyle:"normal", fontWeight:"600", lineHeight:"125%"}}>{weiterleitungen}</div>
                            </div>
                        </div>
                    </div>

                    {/**Divider */}
                    <div style={{width:"100%",height:"2px", backgroundColor:"black", marginTop:"20px", marginBottom:"50px"}}></div>

                    {/**Feedback Liste */}
                    <div style={{display:"flex", flexDirection:"column", width:"100%", height:"auto", alignItems:"center"}}>
                        <div style={{display:"flex", flexDirection:"column", width:"100%", height:"auto", alignItems:"center", gap:"20px"}}>
                        
                            {(!dateEnde||!dateStart||(dateEnde&&dateStart&&dateError)) && <>Wählen Sie einen Zeitraum um Details einzusehen!</>}

                            {combinedActionsFeedback.length<=0 &&
                                <>Wählen Sie einen anderen Zeitraum um weitere Details einzusehen!</>
                            }

                            {combinedActionsFeedback.map((item, index) => (
                            <div key={index}>
                                {item.collection=="feedback" ? (
                                <div style={{display:"flex", flexDirection:"column", width:"1032px", height:"auto", flexShrink:"0", borderWidth:"2px", borderColor:"black", borderStyle:"solid", padding:"15px"}}>
                                    <div style={{display:"flex", flexDirection:"row", width:"100%", height:"43px"}}>
                                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"flex-start", alignItems:"center"}}>
                                            {item.rating == 1 ? (
                                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"25px", fontStyle:"normal", fontWeight:"500", lineHeight:"125%"}}>{item.rating} Stern</div>
                                            ):(
                                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"25px", fontStyle:"normal", fontWeight:"500", lineHeight:"125%"}}>{item.rating} Sterne</div>
                                            )}
                                        </div>
                                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"flex-end", alignItems:"center"}}>
                                            <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"25px", fontStyle:"normal", fontWeight:"500", lineHeight:"125%"}}>{formatiereDatum(item.date)}</div>
                                        </div>
                                    </div>
                                    {item.text != "" && <>
                                    <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"20px", fontStyle:"normal", fontWeight:"400", lineHeight:"125%", marginTop:"20px", marginBottom:"20px"}}>{item.text}</div>
                                    </>}
                                </div>
                                ):(
                                <div style={{display:"flex", flexDirection:"column", width:"1032px", height:"auto", flexShrink:"0", borderWidth:"2px", borderColor:"black", borderStyle:"solid", padding:"15px"}}>
                                    <div style={{display:"flex", flexDirection:"row", width:"100%", height:"43px"}}>
                                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"flex-start", alignItems:"center"}}>
                                            {item.value == 1 ? (
                                            <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"25px", fontStyle:"normal", fontWeight:"500", lineHeight:"125%"}}>{item.value} Stern</div>
                                            ):(
                                                <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"25px", fontStyle:"normal", fontWeight:"500", lineHeight:"125%"}}>{item.value} Sterne</div>
                                            )}
                                        </div>
                                        <div style={{ display:"flex", flex: 1, backgroundColor: "", justifyContent:"flex-end", alignItems:"center"}}>
                                            <div style={{color:"#000", fontFamily:"Hanken Grotesk", fontSize:"25px", fontStyle:"normal", fontWeight:"500", lineHeight:"125%"}}>{formatiereDatum(item.date)}</div>
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                            ))}

                            {combinedActionsFeedback.length>0 &&
                                <div style={{marginBottom:"40px"}}></div>
                            }

                        </div>
                    </div>

                </div>
                {/**ENDE INHALT DER SEITE*/}

                </div>
            </div>
        </div>
      )
}
