import React from 'react'

import './Pages.css';

import NavigationBar from './NavigationBar'
import { useState } from 'react';
import { Button, Input, Text, PasswordInput, Alert, Checkbox, Image, Loader  } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { uploadLocation } from '../sideEffects/sideEffects';

import { BiSolidTrash } from 'react-icons/bi';
import { RiPencilLine } from 'react-icons/ri';
import { Modal } from '@mantine/core';
import { useRef} from 'react'
import Cropper from 'react-easy-crop'
//https://www.npmjs.com/package/react-easy-crop
import { getCroppedImg, getRotatedImage } from './Settings/canvasUtils';
import { useSelector } from 'react-redux';
import { deleteProfilePicture } from '../sideEffects/sideEffects';
import { store } from '../redux/store';
import { uploadLocationImage } from '../sideEffects/sideEffects';
import { getOwnUserObject } from '../sideEffects/sideEffects';
import { setUser } from '../redux/userSlice';
import { updateUserProfilePicture } from '../sideEffects/sideEffects';
import { useNavigate } from 'react-router-dom';
export default function AddLocationScreen() {
    const user = useSelector((state) => state.user.user);
    const locations = useSelector((state) => state.user.user.locations);
    const ownLocations = useSelector((state) => state.user.ownLocations);
    const navigate = useNavigate();

    const { t } = useTranslation();
    const imageRef = useRef()
    const [name, setName] = useState("");
    const [link, setLink] = useState("");
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [openImageModal, setOpenImageModal] = useState(false)

    const [imageSrc, setImageSrc] = useState(null)
    const [croppedImageSrc, setCroppedImageSrc] = useState(null)

    const [openProfileImageModal, setOpenProfileImageModal] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }

    async function upload(){
        if(!name){
            setError("Kein Name")
            return false
        }
        if(!link){
            setError("Kein Link")
            return false
        }
        setError("")
        setLoading(true)
        if(setCroppedImageSrc){
            const croppedImage = await showCroppedImage();
            setOpenProfileImageModal(false)
            const result = await  uploadLocation(link, name, croppedImage, ownLocations?ownLocations:[])
        } else {
            const croppedImage = null;
            setOpenProfileImageModal(false)
            const result = await  uploadLocation(link, name, croppedImage, ownLocations?ownLocations:[])
        }
        setLoading(false)
        navigate("/")
    }

    function handleImageClick(e) {
        e.stopPropagation()
        imageRef.current.click();
    }

    const showCroppedImage = async () => {
        try {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          )
          console.log('donee', { croppedImage })
          setCroppedImageSrc(croppedImage)
          return croppedImage
        } catch (e) {
          console.error(e)
        }
    }

    async function closeModal(){
        await showCroppedImage()
        setOpenImageModal(false)
    }

    function removeImage(){
        setCroppedImageSrc(null)
        setImageSrc(null)
    }

    const handleImageUpload = (e) => {


        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result);
          setOpenImageModal(true)
        };
        reader.readAsDataURL(file);        
      };

    return (
        <div style={{display:"flex", width:"100vw", height:"100vh"}}>
            <Modal opened={openImageModal} onClose={() => setOpenImageModal(false)} centered>
                <div className="ModalContainer">

                        <Cropper className="CropperContainer"
                            image={imageSrc}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={16 / 14}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropShape={"rect"}
                            showGrid={false}
                            style={{ 
                                containerStyle: {
                                    // background:"yellow",
                                    height: "95%",
                                }, 
                                mediaStyle: {
                                    // background:"green"
                                }, 
                                cropAreaStyle: {
                                    // background:"red"
                      
                                } 
                            }}
                        />
                </div>
                <Button variant="transparent" color="gray" radius="lg" onClick={() => setOpenImageModal(false)}>{t("cancel")}</Button>
                <Button variant="filled" radius="md" onClick={() => closeModal()}>{t("saveImage")}</Button>

            </Modal>
            
            {locations ? (
                <NavigationBar showArrow={true} showAvater={true} LinkAvatar="/settings" LinkArrow="/"/>
            ) : (
                <NavigationBar showArrow={false} showAvater={true} LinkAvatar="/settings" LinkArrow="/"/>
            )}
            <div style={{display:"flex", width:"100%", height:"100%", paddingTop:"65px", justifyContent:"center", alignItems:"center"}}>
                <div style={{display:"flex", width:"100%", height:"100%", paddingTop:"20px", paddingBottom:"20px", paddingLeft:"20px", paddingRight:"20px", justifyContent:"center", alignItems:"center"}}>
    
                {/*INHALT DER SEITE*/}
                <div style={{display:"flex", flexDirection:"column"}}>
                    <div onClick={handleImageClick} style={{ width: '420px',height: '300px', overflow: 'hidden'}}>
                        {croppedImageSrc ?  <>
                            <img src={croppedImageSrc} alt="Uploaded" style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top'}}/></>:<>
                        <img src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg" alt="Uploaded" style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top'}}/></>}
                        {/* <img src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png" alt="Uploaded" style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top'}}/> */}
                    </div>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginBottom:"20px"}}>
                    <div onClick={removeImage}>
                            <Button variant="transparent" color="red" className="DeleteText" onClick={null}>
                                <BiSolidTrash /> Löschen
                            </Button>
                        </div>
                        <div onClick={handleImageClick}>
                            <Button variant="transparent" color="red" className="ChangeText" onClick={null}>
                                <RiPencilLine /> Ändern
                            </Button>
                        </div>
                    </div>
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        ref={imageRef}
                        onChange={handleImageUpload}
                    />

                    <div>
                        <Input
                        className='LoginInput'
                        placeholder="Name"
                        required
                        value={name}
                        size="md"
                        variant="filled"
                        onChange={(event) => setName(event.target.value)}
                        />
                    </div>

                    <div>
                        <Input
                        className='LoginInput'
                        placeholder="Link"
                        required
                        value={link}
                        size="md"
                        variant="filled"
                        onChange={(event) => setLink(event.target.value)}
                        />
                    </div>

                    <div className='Button'>
                        <Button variant="filled" color="dark" fullWidth size="md" onClick={() => {upload()}}>
                            {loading ? <Loader color="gray" size="sm" variant="dots" /> : "Speichern"}
                        </Button>
                    </div>
                    { error && (
                        <div className="ErrorMessageNew">
                        <p>{error}</p>
                        </div>
                    )}
                </div>

                </div>
            </div>
        </div>
      )
}
