import { auth } from "./firebase/firebase";
import { store } from './redux/store'
import { setOwnLocations, setUser } from "./redux/userSlice";
import { getOwnUserObject } from "./sideEffects/sideEffects";
import { getOwnLocations } from "./sideEffects/sideEffects";
auth.onAuthStateChanged(async function (user) {
    if (user) {
        if (user.uid) {
            try{
                store.dispatch(setOwnLocations([]))
                const result = await getOwnUserObject();
                const locations = result.locations
                // console.log("resukt", result.locations)
                store.dispatch(setUser(result));
                const locationResult = await getOwnLocations(locations)
                // console.log(locationResult.data)
                const ownLocations = locationResult.data
                store.dispatch(setOwnLocations(ownLocations))
            } catch(e){
                console.log(e)
            }
        }
    } else {
        store.dispatch(setUser(null));
    }
});