import React from "react";

import './Register.css';
import './Pages.css';

import { Button, Input, Text, PasswordInput, Alert, Checkbox  } from '@mantine/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate  } from "react-router-dom"
import { IconAt } from '@tabler/icons-react';
import { useDispatch} from 'react-redux';
import { setValue } from '../redux/counterSlice';
import { useTranslation } from "react-i18next";
import { SignInWithGoogle, register, addUserDocument} from "../sideEffects/sideEffects";
import FooterBar from "../components/FooterBar";

function Register() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);

  function clientSideValidation() {
    if (password !== password2) {
        setError(t("auth/passwords-not-matching"))
        return false
    }
    if (email === "") {
        setError(t("auth/missing-email"))
        return false
    }
    if (password === "") {
        setError(t("auth/missing-password"))
        return false
    }
    if (checked1 === false){
      setError(t("auth/missing-privacyPolicy"))
      return false
    }
    if (checked2 === false){
      setError(t("auth/missing-termsAndConditions"))
      return false
    }
    return true
  }

  async function registerGoogle(){
    setError('')
    setLoading(true)
    try {
      await SignInWithGoogle();
      navigate("/")
    } catch (error) {
      console.log("error", error.message)
      setLoading(false)
      setError(t(error.code))
    }
  }

  async function registerSubmit() {

    if (!clientSideValidation()) {
      return
    }

    setError('')
    setLoading(true)
    try {
      await register(email, password)
      await addUserDocument();
      dispatch(setValue(0))
      navigate('/');
      setLoading(false)
    } catch (error) {
      console.log("error", error.message)
      setLoading(false)
      setError(t(error.code))
    }
    setLoading(false)
  }

    return (
      <div className='Container'>
        <div className="ContentContainer">
          <Text className='TitleText'>{t("welcomeRegister")}</Text>
          <div className='ContextInputs'>
            <Input
              className='LoginInput'
              icon={<IconAt />}
              placeholder={t("email")}
              required
              value={email}
              size="md"
              variant="filled"
              onChange={(event) => setEmail(event.target.value)}
            />
            <PasswordInput
              className='LoginInput'
              placeholder={t("password")}
              description={t("auth/weak-password")}
              value={password}
              size="md"
              variant="filled"
              onChange={(event) => setPassword(event.target.value)}
            />
            {password===password2 ?
            <PasswordInput
              className='LoginInput'
              placeholder={t("passwordRepeat")}
              value={password2}
              size="md"
              variant="filled"
              onChange={(event) => setPassword2(event.target.value)}
            />
            :
            <PasswordInput
              className='LoginInput'
              placeholder={t("passwordRepeat")}
              value={password2}
              size="md"
              variant="filled"
              error
              onChange={(event) => setPassword2(event.target.value)}
            />
            }
          </div>
          <div className="checkbox">
          <Checkbox
              checked={checked1} onChange={(event) => setChecked1(event.currentTarget.checked)}
              label={
                <>
                  {t("accept")}{' '}
                  <Link to="/privacy-policy" target="_blank">{t("privacyPolicy")}</Link>
                </>
              }
              color="dark"
            />
          </div>
          <div className="checkbox">
          <Checkbox
              checked={checked2} onChange={(event) => setChecked2(event.currentTarget.checked)}
              label={
                <>
                  {t("accept")}{' '}
                  <Link to="/terms-and-conditions" target="_blank">{t("termsAndConditions")}</Link>
                </>
              }
              color="dark"
            />
          </div>
          { error && (
            <div className="ErrorMessageNew">
              <p>{error}</p>
            </div>
          )}
          <div className='Button'>
            <Button fullWidth color="dark" size="md" disabled={loading} onClick={() => {registerSubmit()}}>{t("signUp")}</Button>
          </div>

          <div class="line-wrapper">
            <div class="line"></div>
            <p>{t("orRegisterWith")}</p>
            <div class="line"></div>
          </div>
          <div className="ButtonIcon-wrapper">
            <div className='ButtonIcon'>
              <Button disabled={loading} variant="outline" color="gray" size="md" onClick={() => {registerGoogle()}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path d="M6.41996 15.4656L5.59721 18.537L2.59008 18.6006C1.69139 16.9338 1.18164 15.0266 1.18164 13C1.18164 11.0403 1.65825 9.1922 2.50306 7.56494H2.50371L5.1809 8.05576L6.35366 10.7169C6.10821 11.4325 5.97442 12.2007 5.97442 13C5.97451 13.8675 6.13166 14.6987 6.41996 15.4656Z" fill="#FBBB00"/>
                  <path d="M24.6116 10.7921C24.7474 11.507 24.8181 12.2453 24.8181 12.9999C24.8181 13.846 24.7292 14.6713 24.5597 15.4675C23.9844 18.1765 22.4811 20.5421 20.3987 22.2161L20.398 22.2155L17.026 22.0434L16.5487 19.0642C17.9305 18.2538 19.0104 16.9856 19.5793 15.4675H13.2598V10.7921H19.6715H24.6116Z" fill="#518EF8"/>
                  <path d="M20.3981 22.2156L20.3988 22.2162C18.3735 23.8441 15.8007 24.8181 13.0001 24.8181C8.49942 24.8181 4.58645 22.3026 2.59033 18.6006L6.42021 15.4656C7.41824 18.1292 9.98773 20.0253 13.0001 20.0253C14.2949 20.0253 15.5079 19.6753 16.5488 19.0643L20.3981 22.2156Z" fill="#28B446"/>
                  <path d="M20.5432 3.90253L16.7146 7.03693C15.6374 6.36357 14.364 5.97459 12.9997 5.97459C9.91916 5.97459 7.30162 7.95769 6.35358 10.7168L2.50358 7.56487H2.50293C4.46983 3.77267 8.43215 1.18176 12.9997 1.18176C15.8672 1.18176 18.4964 2.2032 20.5432 3.90253Z" fill="#F14336"/>
                </svg>
              </Button>
              <Button disabled={loading} variant="outline" color="gray" size="md" onClick={() => {registerGoogle()}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="88.428 12.828 107.543 207.085">
                  <path d="M158.232 219.912v-94.461h31.707l4.747-36.813h-36.454V65.134c0-10.658 2.96-17.922 18.245-17.922l19.494-.009V14.278c-3.373-.447-14.944-1.449-28.406-1.449-28.106 0-47.348 17.155-47.348 48.661v27.149H88.428v36.813h31.788v94.461l38.016-.001z" fill="#3c5a9a"/>
                </svg>
              </Button>
              <Button disabled={loading} variant="outline" color="gray" size="md" onClick={() => {registerGoogle()}}>
                <svg height="26" width="26" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3.552713678800501e-15 820 950">
                  <path d="M404.345 229.846c52.467 0 98.494-20.488 138.08-61.465s59.38-88.626 59.38-142.947c0-5.966-.472-14.444-1.414-25.434-6.912.942-12.096 1.727-15.552 2.355-48.383 6.908-90.954 30.615-127.713 71.12-36.758 40.506-55.137 83.838-55.137 129.996 0 5.337.785 14.13 2.356 26.375zM592.379 950c37.387 0 78.701-25.59 123.943-76.772S796.122 761.915 820 692.836c-88.912-45.844-133.368-111.626-133.368-197.348 0-71.591 35.973-132.82 107.92-183.688-49.954-62.486-115.931-93.729-197.931-93.729-34.56 0-66.134 5.181-94.724 15.543l-17.908 6.594-24.035 9.42c-15.709 5.966-30.004 8.95-42.885 8.95-10.054 0-23.25-3.455-39.586-10.363l-18.38-7.536-17.436-7.065c-25.449-10.676-52.782-16.014-82-16.014-78.23 0-141.065 26.376-188.506 79.128C23.72 349.479 0 419.03 0 505.379c0 121.517 38.015 233.772 114.046 336.763C166.828 914.047 215.054 950 258.724 950c18.537 0 36.916-3.611 55.138-10.833l23.092-9.42 18.38-6.594c25.762-9.106 49.482-13.659 71.16-13.659 22.935 0 49.326 5.81 79.173 17.427l14.609 5.652C550.75 944.191 574.786 950 592.379 950z"/>
                </svg>
              </Button>
            </div>
          </div>
          <div className="ExtraLink">
            <p>{t("alreadyHaveAnAccount")}{' '}
              <Link to="/login">{t("signInHere")}</Link>
            </p>
          </div>
        </div>
        <FooterBar/>
      </div>
    );
  }
  
  export default Register;