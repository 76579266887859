import React from 'react'
import NavigationBar from "./NavigationBar";
import { useNavigate } from 'react-router-dom';
import { Button, Input, Text, PasswordInput, Alert, Checkbox  } from '@mantine/core';
import { useSelector } from 'react-redux';

export default function LocationOverview() {
  const user = useSelector((state) => state.user.user);
  const locations = useSelector((state) => state.user.ownLocations);

  const navigate = useNavigate();

  function locationsClick(name) {
    navigate(`/locationDetails?location=${encodeURIComponent(name)}`);
  }

  function newLocation(){
    navigate("/addLocation")
  }

  return (
    <div style={{display:"flex", width:"100vw", height:"100vh"}}>
        <NavigationBar showArrow={false} showAvater={true} LinkAvatar="/settings" LinkArrow=""/>
        <div style={{display:"flex", width:"100%", height:"100%", paddingTop:"65px", justifyContent:"center", alignItems:"center"}}>
            <div style={{display:"flex", width:"100%", height:"100%", paddingTop:"20px", paddingBottom:"20px", paddingLeft:"20px", paddingRight:"20px", gap:"10px"}}>

            {/*INHALT DER SEITE*/}
            {locations.map((objekt, index) => (
              <div key={index} onClick={()=>locationsClick(objekt.locationKey)}>
                <div style={{ width: '420px',height: '300px',overflow: 'hidden',}}>
                    <img src={objekt.logoURL?objekt.logoURL:'https://craftsnippets.com/articles_images/placeholder/placeholder.jpg'} alt="Uploaded" style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top',}}/>
                </div>
                <div style={{color:"var(--dark, #222c1e)", fontFamily:"Hanken Grotesk", fontStyle:"normal", fontSize:"25px", fontWeight:"700", letterSpacing:"-0.3"}}>{objekt.name}</div>
              </div>
            ))}

            <div onClick={newLocation}>
                <div style={{ display:"flex", width: '420px',height: '300px', justifyContent:"center", alignContent:"center", alignItems:"center", borderWidth:"1px", borderColor:"black", borderStyle: "solid"}}>
                    +
                </div>
            </div>

            </div>
        </div>
    </div>
  )
}
